const comments = {
  "BAhvZrRwcfu":[
    {
      "text":"Totally need to try this.",
      "user": "heavymetaladam"
    }
  ],
  "BAcyDyQwcXX":[
    {
      "text":"Wes. WE should have lunch.",
      "user": "jdaveknox"
    },
    {
      "text":"#adults",
      "user": "jdaveknox"
    },
    {
      "text":"@jdaveknox yes!",
      "user": "wesbos"
    },
    {
      "text":"😍 love Hamilton!",
      "user": "willowtreemegs"
    }
  ],
  "BAPIPRjQce9":[
    {
      "text":"Those are cute! They're like silver dollar pancakes.",
      "user": "rrsimonsen"
    },
    {
      "text":"I like baby pancakes but gluten free please!! I'll bring the coffee!! See you in 6 days!!!!!! 😝😛😝♥️",
      "user": "terzisn"
    },
    {
      "text":"... and apparently growing baby. 👀. Yum.",
      "user": "henrihelvetica"
    },
    {
      "text":"@wesbos 👍 my daughter is a pancake eating machine!",
      "user": "brentoage"
    },
    {
      "text":"Nice stove!",
      "user": "haaps"
    },
    {
      "text":"Genius bottle use! Do you make a single batch of batter or make a lot and freeze it?",
      "user": "gobananna"
    },
    {
      "text":"@gobananna I just made a batch and put in in a FIFO bottle. Keeps in the fridge for a few days.",
      "user": "wesbos"
    },
    {
      "text":"@haaps thanks! It's a Jenn air - so nice to cool with!",
      "user": "wesbos"
    },
    {
      "text":"Where would you go and for how long, if you had location freedom? - Greg 🌎",
      "user": "world_greg"
    }
  ],
  "BAF_KY4wcRY":[
    {
      "text":"Looking great Wes! I'd like to see the other side of the room too.",
      "user": "axcdnt"
    },
    {
      "text":"I've never caught your podcast. Have one right? Btw - they don't have a Canary pillow? 😝",
      "user": "henrihelvetica"
    },
    {
      "text":"Great way to start the year.",
      "user": "pmgllc"
    },
    {
      "text":"Are there 4k monitors?",
      "user": "alexbaumgertner"
    },
    {
      "text":"@axcdnt that is where I put all the junk. I'll have to clean that side too @henrihelvetica no podcast yet! @pmgllc ohh yeah! @alexbaumgertner yep - the main one is 4K - I'm loving it",
      "user": "wesbos"
    },
    {
      "text":"That chrome pillow. 😉",
      "user": "imagesofthisandthat"
    },
    {
      "text":"@wesbos is that the Dell 4k? The MacBook Pro powers it well? I also have a Retina™ / x1 setup as well. Very handy.",
      "user": "henrihelvetica"
    },
    {
      "text":"#minimalsetups",
      "user": "wesbos"
    }
  ],
  "_4jHytwcUA":[
    {
      "text":"that is the sound of success!",
      "user": "mdxprograms"
    }
  ],
  "_zbaOlQcbn":[
    {
      "text":"Did she get to eat her letter?",
      "user": "pathiebert"
    },
    {
      "text":"Nope @pathiebert! She has too much teeth now (8) so that would definitely be her first cavity 😉",
      "user": "kaitbos"
    }
  ],
  "_rmvQfQce8":[
    {
      "text":"A+",
      "user": "mrjoedee"
    },
    {
      "text":"I recently went to a ramen place in Philly. So amazing!",
      "user": "jrtashjian"
    }
  ],
  "_ep9kiQcVy":[
    {
      "text":"All bundled up!  Where ya goin?",
      "user": "sophie_and_sadie"
    }
  ],
  "_XpJcrwcSn":[
    {
      "text":"Super congrats! That's wicked cool and looks great.",
      "user": "pmgllc"
    },
    {
      "text":"real live paper magazine? woah haha. flex box is awesome though, could rage quit without it",
      "user": "tjholowaychuk2"
    },
    {
      "text":"@tjholowaychuk2 haha yes! Old school stylez!",
      "user": "wesbos"
    }
  ],
  "_KnU7MwceA":[

  ],
  "_HMejJQcY5":[
    {
      "text":"👌",
      "user": "t_volpe"
    },
    {
      "text":"Nice shot, mister!",
      "user": "imagesofthisandthat"
    }
  ],
  "_Fq2zmwcaz":[
    {
      "text":"😍",
      "user": "melsariffodeen"
    },
    {
      "text":"Very cool!",
      "user": "ka11away"
    }
  ],
  "_A2r0aQcfD":[
    {
      "text":"Uhu!",
      "user": "lucascaixeta"
    }
  ],
  "1rhFawccs":[
    {
      "text":"What's your lighting source?",
      "user": "seth_mcleod"
    },
    {
      "text":"And what are you using for XLR mix adapter? I found a big price jump between $55 range and $170 range.",
      "user": "pmgllc"
    },
    {
      "text":"I still need a desk boom for mine mic. Nice upgrades",
      "user": "stolinski"
    }
  ],
  "pjx-gQcVi":[

  ],
  "oTZ0zQcWt":[
    {
      "text":"Love the coat! Where's it from? Lol",
      "user": "_lindersss"
    }
  ],
  "mxKQoQcQh":[

  ],
  "hZh6IQcfN":[
    {
      "text":"What do we gotta do to get some :)? @wesbos",
      "user": "jonasbad"
    },
    {
      "text":"Might drop by today - real quick. Trade you an illegal pin for these? Lol. @wesbos",
      "user": "henrihelvetica"
    },
    {
      "text":"I'm with @jonasbad on this. What we gotta do? :D",
      "user": "datamoshr"
    },
    {
      "text":"@jonasbad @datamoshr I'll post them up on my blog soon!",
      "user": "wesbos"
    },
    {
      "text":"Want",
      "user": "kamuelafranco"
    },
    {
      "text":"want!",
      "user": "josemanuelxyz"
    },
    {
      "text":"#Top",
      "user": "gabrielvieira.me"
    }
  ],
  "fasqlQceO":[
    {
      "text":"Where's lux at? 💤?",
      "user": "henrihelvetica"
    },
    {
      "text":"Love this house during the holidays! And all other times of the year...",
      "user": "danielleplas"
    }
  ],
  "VBgtGQcSf":[
    {
      "text":"@dogsandbrew",
      "user": "likea_bos"
    },
    {
      "text":"Next on my list!",
      "user": "tomwalsham"
    },
    {
      "text":"Is that from collective arts ?",
      "user": "trevorb_91"
    }
  ],
  "FpTyHQcau":[
    {
      "text":"@kaitbos  that vest!!! 😍",
      "user": "courtneyeveline"
    },
    {
      "text":"I just love her! @kaitbos",
      "user": "kalibrix"
    },
    {
      "text":"@courtneyeveline I know! My friend gave it to her and I wanted a matching one but only Lux can pull it off. She's so fancy 😉",
      "user": "kaitbos"
    },
    {
      "text":"Char has that vest!!! Super cute!",
      "user": "jennlensink"
    },
    {
      "text":"You'll have to meet her soon @kalibrix!!",
      "user": "kaitbos"
    },
    {
      "text":"Haha @kaitbos so true, babies these days are sooo stylish. 😎",
      "user": "courtneyeveline"
    },
    {
      "text":"JavaScript 😄😆🙋",
      "user": "lucascaixeta"
    },
    {
      "text":"That hoodie is amazing! Where did you get it?",
      "user": "br11x"
    },
    {
      "text":"@br11x I did a teespring a few months ago - maybe I'll do another one soon",
      "user": "wesbos"
    }
  ],
  "B3eiIwcYV":[
    {
      "text":"If you get in the mood for authentic Italian pizza, check out @backspaceaustin - it's👌🏻",
      "user": "dessie.ann"
    },
    {
      "text":"😱 jealous",
      "user": "jenngbrewer"
    }
  ]
};

export default comments;
